import { decodeBase64JsonToken } from "@cfra-nextgen-frontend/shared/src/utils";
import { Auth } from "aws-amplify";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';


const useVerifyLogin = () => {
    const location = useLocation();
    const validate = useRef(false);
    const [isLoginVerified, setIsLoginVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const loginToken = useMemo(() => decodeBase64JsonToken(location.search), [location.search]);

    const login = useCallback(async () => {
        if (!loginToken?.username || !loginToken?.pw) {
            setIsLoading(false);
            return;
        }
        if (validate.current) {
            return;
        }

        validate.current = true;
        try {
            const userAuth = await Auth.signIn(loginToken.username, loginToken.pw);
            if (userAuth) {
                await Auth.signOut();
                setIsLoginVerified(true);
            }
        } catch (error: any) {
            try {
                const user = await Auth.currentAuthenticatedUser();
                setIsLoginVerified(!!user);
            } catch {
                setIsLoginVerified(false);
            }
        } finally {
            setIsLoading(false);
        }
    }, [loginToken]);

    useEffect(() => {
        login();
    }, [login]);

    return { isLoginVerified, isLoading, loginToken };
};

export default useVerifyLogin;