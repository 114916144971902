export async function getFontsBase64String(filePaths: string[]) {
    try {
        const promises = filePaths.map((filePath) => convertFileToBase64(filePath));
        const base64Strings = await Promise.all(promises);
        return base64Strings;
    } catch (error) {
        console.error('Error converting files to base64:', error);
    }
}

export async function convertFileToBase64(filePath: string) {
    try {
        const response = await fetch(filePath);
        const blob = await response.blob();
        return convertBlobToBase64(blob);
    } catch (error) {
        console.error(`Error converting file ${filePath} to base64:`, error);
        return null;
    }
}

export function convertBlobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export function decodeBase64JsonToken(urlQueryParams: string): Record<string, any> {
    try {
        const queryParams = new URLSearchParams(urlQueryParams);
        const base64Token = queryParams.get('token')?.trim();
        if (!base64Token || !/^[A-Za-z0-9+/=]+$/.test(base64Token)) {
            return {};
        }

        const payload = JSON.parse(atob(base64Token));
        return payload && typeof payload === 'object' ? payload : {};
    } catch {
        return {};
    }
};